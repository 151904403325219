@charset "UTF-8";


// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;


// ==================================================
//  Page Top（index.ejs）
// ==================================================
.page-top {
  padding: 0;
  color: color.$write-main;
  background-color: color.$bg-white;

  // Mainvisual
  .mainvisual {
    display: block;
    width: 100%;
    height: 100vh;
    min-height: 800px;
    position: relative;

    @include mixin.pc {
      border-bottom: 14px solid color.$bg-black;
    }

    @include mixin.sp {
      height: calc(100vh - 48px);
      min-height: 0;
    }

    // Javascript
    &.js_mainvisual {
      .mainvisual {
        &_scrollGuide {
          @include mixin.sp {
            opacity: 0;
            transition: opacity 0.45s;
          }
        }

        &_logo {
          opacity: 0;
          transition: opacity 0.45s;
        }

        &_typography {
          opacity: 0;
          transition: opacity 0.45s;
        }
      }

      &-active {
        .mainvisual{
          &_scrollGuide {
            @include mixin.sp {
              opacity: 1;
            }
          }

          &_logo {
            opacity: 1;
          }

          &_typography {
            opacity: 1;
          }
        }
      }
    }

    &_logo {
      display: block;
      width: 253px;
      margin: 0;
      position: fixed;
      z-index: 1;
      top: calc(50% + 30px);
      left: 50%;
      transform: translate(-50%, -50%);

      @include mixin.sp {
        top: calc(50% - 38px);
      }

      & > img {
        display: block;
        width: 100%;
      }
    }

    &_typography {
      display: block;
      width: 100%;
      margin: 0;
      position: absolute;
      z-index: 2;
      bottom: -1px;

      & > img {
        display: block;
        width: 100%;
        max-width: 651px;
        margin: 0 auto;

        @include mixin.sp {
          max-width: 375px;
        }
      }
    }

    &_scrollGuide {
      display: none;

      @include mixin.sp {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 1.4rem;
        list-style: 1;
        font-weight: bold;
        position: absolute;
        z-index: 2;
        bottom: 74px;
        left: 50%;
        transform: translateX(-50%);
      }

      &::after {
        @include mixin.sp {
          content: "";
          display: block;
          width: 1px;
          height: 61px;
          background-color: color.$bg-black;
          margin: 16px auto 0;
        }
      }
    }
  }

  // Message
  .message {
    display: block;
    width: 100%;
    color: color.$write-white;
    background-color: color.$bg-black;
    position: relative;
    z-index: 2;

    @include mixin.sp {
      display: flex;
      flex-direction: column-reverse;
    }

    &_item {
      display: flex;
      align-items: stretch;

      @include mixin.sp {
        flex-direction: column;
      }

      &:nth-of-type(even) {
        flex-direction: row-reverse;

        @include mixin.sp {
          flex-direction: column-reverse;
        }

        .message_item {
          &_text {
            @include mixin.sp {
              display: none;
            }
          }
        }
      }

      &_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        position: relative;
        padding: 70px 57px 84px 180px;

        @include mixin.sp {
          width: 100%;
          padding: 32px 33px 47px 36px;
        }

        & > :first-child {
          margin-top: 0;
        }

        &_symbol {
          display: block;
          width: 147px;
          margin: 0 0 40px;

          @include mixin.sp {
            margin: 0 0 0 auto;
            transform: translateX(13px);
          }
        }

        &_paragraph {
          display: block;
          font-size: 1.8rem;
          line-height: 1.94;
          font-weight: bold;
          margin: 26px 0 0;
          letter-spacing: -0.05em;

          @include mixin.sp {
            font-size: 1.6rem;
            line-height: 2.19;
            margin-top: 24px;
          }
        }

        &_logo {
          display: block;
          width: 253px;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @include mixin.sp {
            display: none;
          }
        }
      }

      &_image {
        width: 50%;
        position: relative;
        background-color: color.$bg-black;

        @include mixin.pc {
          min-height: 800px;
        }

        @include mixin.sp {
          width: 100%;
        }

        & > img, & > video {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;

          @include mixin.sp {
            height: 428px;
          }
        }
      }
    }
  }

  // Parts Works List
  .parts-worksList {
    margin-top: 468px;
    position: relative;
    z-index: 2;

    @include mixin.pc {
      margin-bottom: 450px;
    }

    @include mixin.sp {
      margin-top: 329px;
    }
  }

  // Design
  .design {
    display: block;
    width: 100%;
    color: color.$write-white;
    background-color: color.$bg-black;
    position: relative;
    z-index: 2;

    &_item {
      display: flex;
      align-items: stretch;

      @include mixin.sp {
        flex-direction: column;
      }

      &:nth-of-type(even) {
        flex-direction: row-reverse;

        @include mixin.sp {
          flex-direction: column;
        }

        .design_item {
          &_text {
            padding: 70px 180px 84px 57px;

            @include mixin.sp {
              padding: 51px 36px 70px;
            }

            &_hr {
              @include mixin.pc {
                transform: translateX(-58px);
              }
            }
          }
        }
      }

      &_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        position: relative;
        padding: 70px 57px 84px 180px;

        @include mixin.sp {
          width: 100%;
          padding: 51px 35px 70px;
        }

        & > :first-child {
          margin-top: 0;
        }

        &_hr {
          width: calc(100% + 58px);
          height: 1px;
          border: none;
          background-color: color.$bg-gray;
          margin: 25px 0 0;

          @include mixin.sp {
            width: 100%;
            margin-top: 50px;
          }
        }

        &_title {
          font-size: 3.0rem;
          line-height: 1.77;
          font-weight: bold;
          letter-spacing: -0.05em;
          margin: 0;

          @include mixin.sp {
            font-size: 2.4rem;
            line-height: 1.75;
          }
        }

        &_concept {
          display: block;
          width: 100%;
          margin-top: 68px;
          padding-bottom: 45px;
          position: relative;

          @include mixin.sp {
            margin: 40px 0 15px;
            padding-bottom: 29px;
          }

          &::after {
            content: "";
            display: block;
            width: calc(100% + 58px);
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: color.$bg-white;

            @include mixin.sp {
              width: 100%;
            }
          }

          & > img {
            display: block;
            width: 100%;
            max-width: 470px;

            @include mixin.sp {
              max-width: 304px;
            }
          }
        }

        &_paragraph {
          display: block;
          font-size: 1.7rem;
          line-height: 1.94;
          font-weight: bold;
          margin: 26px 0 0;
          letter-spacing: -0.05em;

          @include mixin.sp {
            font-size: 1.5rem;
            line-height: 2.07;
            margin-top: 30px;
            letter-spacing: -0.08em;
          }

          &_strong {
            display: block;
            font-size: 2.3rem;
            line-height: 1.34;
            margin: 36px 0;
            padding-left: 8.5em;
            text-indent: -8.5em;
            letter-spacing: -0.05em;

            @include mixin.sp {
              font-size: 2.2rem;
              line-height: 1.64;
              padding-left: 1em;
              text-indent: -1em;
              margin: 22px 0;
            }
          }
        }

        &_link {
          display: block;
          width: 100%;
          max-width: 319px;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 1;
          border: 2px solid color.$bg-white;
          margin: 60px 0 0;
          text-align: center;
          position: relative;
          text-decoration: none;
          padding: 18.5px 0;
          color: color.$write-white;
          background-color: transparent;
          transition: background-color 0.3s, color 0.3s;

          @include mixin.hover {
            &::after {
              right: -15px;
            }
          }

          @include mixin.sp {
            width: 304px;
            margin-top: 42px;
            font-size: 1.8rem;
            padding: 17.5px 0;
          }

          &::after {
            content: "";
            display: block;
            width: 48px;
            height: 2px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            background-color: color.$bg-white;
            transition: right 0.3s;

            @include mixin.sp {
              width: 42px;
            }
          }
        }
      }

      &_image {
        width: 50%;
        position: relative;
        background-color: color.$bg-black;

        @include mixin.pc {
          min-height: 800px;
        }

        @include mixin.sp {
          width: 100%;
        }

        & > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;

          @include mixin.sp {
            height: 428px;
          }
        }
      }
    }
  }

  // Company
  .company {
    display: block;
    width: 100%;
    margin-top: 687px;
    color: color.$write-main;
    background-color: color.$bg-white;
    position: relative;
    z-index: 2;
    padding: 120px 0 120px;

    @include mixin.sp {
      margin-top: 0;
      padding: 109px 0 105px;
    }

    &_table {
      display: block;
      width: 100%;
      max-width: 860px;
      margin: 106px auto 0;
      padding: 0;

      @include mixin.sp {
        margin-top: 67px;
        padding: 0 20px;
      }

      &_item {
        display: flex;
        column-gap: 28px;

        @include mixin.sp {
          column-gap: 16px;
        }

        &_title {
          display: block;
          width: 173px;
          text-align: center;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 1.56;
          border-bottom: 1px solid color.$bg-gray;
          padding: 18px 30px;

          @include mixin.sp {
            width: 94px;
            font-size: 1.5rem;
            line-height: 1.29;
            padding: 11.5px 2px;
          }
        }

        &_content {
          display: block;
          width: calc(100% - 173px);
          margin: 0;
          font-size: 1.8rem;
          font-weight: bold;
          line-height: 1.56;
          border-bottom: 1px solid color.$bg-gray;
          padding: 18px 30px;

          @include mixin.sp {
            width: calc(100% - 110px);
            font-size: 1.4rem;
            line-height: 1.29;
            padding: 12px 2px;
          }

          a {
            text-decoration: none;
            @include mixin.hoverOpacity60;
          }

          &_list {
            list-style: none;
            margin: 0;
            padding: 0;

            &_item {
              padding-left: 1.5em;
              text-indent: -1.5em;

              @include mixin.sp {
                margin-top: 8px;
              }

              &:first-of-type {
                @include mixin.sp {
                  margin-top: 0;
                }
              }

              &::before {
                content: "○ ";
              }
            }
          }
        }
      }
    }
  }

  // Instagram
  .instagram {
    display: block;
    width: 100%;
    color: color.$write-main;
    background-color: color.$bg-white;
    position: relative;
    z-index: 2;
    padding: 120px 0 105px;
    margin-bottom: 457px;

    @include mixin.sp {
      padding: 105px 0 35px;
      margin-bottom: 457px;
    }

    // Page Header
    .pageHeader {
      &_titleJa {
        @include mixin.sp {
          font-size: 2.3rem;
        }
      }
    }
  }
}